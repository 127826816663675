import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    width: '100%',
    padding: '15px 25px',
    backgroundColor: theme.palette.garamMasala.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '15px',

    [theme.breakpoints.up('xl')]: {
      padding: '15px 50px',
    },

    [theme.breakpoints.down(1380)]: {
      padding: '10px 25px',
      height: '70px',
      overflowY: 'hidden',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      columnGap: '5px',
    },
  },

  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '15px',

    [theme.breakpoints.down(1380)]: {
      display: 'none',
    },
  },

  container: {
    flexShrink: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15px',

    [theme.breakpoints.up('xl')]: {
      columnGap: '25px',
    },

    [theme.breakpoints.down(1380)]: {
      height: '83px',
    },
  },

  textSvgWrapper: {
    flexShrink: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15px',

    [theme.breakpoints.up('xl')]: {
      columnGap: '25px',
    },
  },

  svgWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,

    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1.5)',
    },

    [theme.breakpoints.up(1380)]: {
      display: 'none',
    },
  },
});
